import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Banner from '../CreativeTemplates/Banner';
import Interstitial from '../CreativeTemplates/Interstitial';
import HalfAndHalf from '../CreativeTemplates/HalfAndHalf';
import Inline from '../CreativeTemplates/Inline';
import VideoInterstitial from '../CreativeTemplates/VideoInterstitial';
import Gallery from '../CreativeTemplates/Gallery';
import MobileBannerVideo from '../CreativeTemplates/MobileBannerVideo';
import Flipper from '../CreativeTemplates/Flipper';
import Scratch from '../CreativeTemplates/Scratch';
import BounceGame from '../CreativeTemplates/BounceGame';
import VideoTimerBanner from '../CreativeTemplates/VideoTimerBanner';
import DesktopTimerBanner from '../CreativeTemplates/DesktopTimerBanner';
import VideoGallery from '../CreativeTemplates/VideoGallery';
import CollectOrEvadeGame from '../CreativeTemplates/CollectOrEvadeGame';
import ProgramVideo from '../CreativeTemplates/ProgramVideo';
import CreativeAssetsMenu from '../CreativeAssetsMenu/CreativeAssetsMenu';
import ActionFooter from '../Layout/ActionFooter';
import LoadingIndicator from '../Layout/LoadingIndicator';

import { CREATIVE, MAIN } from '../../constants/path';
import { toast } from 'react-toastify';
import { clearAllBannerStyleTags, getAnimationData, getAnimationClassName, formatCreativeForSave } from '../../utils.js';
import { getCreative, postCreative, resizeGif, resizeVideo } from '../../api_requests';
import { handleCurrentVideos, handleFieldInit } from '../CreativeTemplates/handlers';
import {
    CUSTOM_BANNER,
    INTERSTITIAL,
    HALF_HALF,
    INLINE,
    VIDEO_INTERSTITIAL,
    GALLERY,
    MOBILE_BANNER_VIDEO,
    FLIPPER,
    SCRATCH,
    BOUNCE_GAME,
    VIDEO_TIMER_BANNER,
    DESKTOP_TIMER_BANNER,
    VIDEO_GALLERY,
    COLLECT_OR_EVADE_GAME,
    PROGRAM_VIDEO,
    HALF_VIDEO,
    UNIVERSAL,
    STICKY_MOBILE
} from '../utils/options/creativeTypes';
import { POSTER, VIDEO } from '../../constants/imageTypes';
import * as actionTypes from '../../store/action';
import HalfVideo from '../CreativeTemplates/halfVideo';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import CreativeRedirectionMenu from '../CreativeRedirectionMenu/CreativeRedirectionMenu.js';
import NewCreative from '../PopUp/NewCreative.js';
import Universal from '../CreativeTemplates/Universal.js';

export default function CreativePage() {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const creativeTabs = ['General', 'Creative', 'Redirection'];

    const creative = useSelector(state => state.creative);
    const background = useSelector(state => state.creative.backgroundColor);
    const didMountRef = React.useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.token);
    const images = useSelector(state => state.img.images);
    const texts = useSelector(state => state.txt.texts);
    const shapes = useSelector(state => state.shape.shapes);
    const currentVideos = useSelector(state => state.creative.currentVideos);
    const nameVal = useSelector(state => state.creative.name);
    const currentType = useSelector(state => state.creative.type);
    const imageCompression = useSelector(state => state.localState.imageCompression);
    const [requestingData, setRequestingData] = useState(true);

    const currentPath = location.pathname.replace('/creative/', '');

    // not used in any part of the code :O
    // const handleReturn = useCallback(() => {
    //     dispatch({ type: actionTypes.SET_LEFT_DRAWER_CURRENT_TAB , value: 0 });
    //     dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
    //     dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
    //     dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });
    //     navigate(MAIN);
    // }, [navigate, dispatch]);

    const setCurrentVideos = useCallback((creative) => {
        if(creative.assets?.filter(asset => asset.type === 'video')) {
            const videoAssets = creative.assets?.filter(asset => asset.type === 'video');
            handleCurrentVideos(dispatch, videoAssets);
        }
    }, [dispatch]);

    const handleCreativeInit = useCallback((data) => {
        handleFieldInit(dispatch, data.creative);
        setCurrentVideos(data.creative);
        setRequestingData(false);
    }, [dispatch, setCurrentVideos]);

    // runs when clicking "save"
    // this function needs a rewrite since its very important and is messy
    const handleProceed = async () => {
        dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
        dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
        dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });

        if ((!creative.name && !nameVal) || (creative.name === "" && nameVal === "")) {
            toast.error('Error: Missing creative name');
            return;
        } 
        // else {
        //     dispatch({ type: actionTypes.SET_CREATIVE_FIELDS, name: nameVal, creativeType: currentType, clientId: currentClient, campaignId: currentCampaign });
        // }
        if (creative.type === CUSTOM_BANNER) {
            if ((images.length < 1 && texts.length < 1 && shapes.length < 1) && (!creative.backgroundImage && !creative.backgroundVideo)) {
                toast.error('Error: Empty creative');
                return;
            }
        } else {
            if ((typeof creative.autoCloseTimer === 'number' && isNaN(creative.autoCloseTimer)) || (typeof creative.autoCloseTimer === 'string' && creative.autoCloseTimer.includes('-'))) {
                toast.error('Error: Invalid auto close timer input');
                return;
            }
        }

        if(creative.type === UNIVERSAL) {
            if (creative.assets.find(asset => asset.url === '') !== undefined || creative.assets.length === 0) {
                toast.error('Error: Missing assets');
                return;
            }
        }

        if ([INTERSTITIAL, HALF_HALF, FLIPPER, SCRATCH, COLLECT_OR_EVADE_GAME].includes(creative.type)) {
            if (creative.assets.find(asset => asset.url === '') !== undefined) {
                toast.error('Error: Missing image customs');
                return;
            }
        }

        if (creative.type === INLINE) {
            const videoOnly = creative.videoOnly;
            const showVideo = creative.showVideo;
            const userSetVideo = creative.assets?.find(i => i.type === 'video');
            const userSetPoster = creative.assets?.find(i => i.type === 'poster');
            
            if ( // only video
                videoOnly 
                && showVideo
                && userSetVideo.url === ""
            ) {
                toast.error('Error: Missing video');
                return; 
            } else if ( // video with poster
                !videoOnly 
                && showVideo
                && (
                    userSetPoster.url === ""
                    || userSetVideo.url === ""
                )
            ) { 
                toast.error('Error: Missing video or poster');
                return;
            } else if ( // impossible situation
                videoOnly 
                && !showVideo
            ) { 
                toast.error('Error: cant be video only and show video off');
                return;  
            } else if ( // only poster
                !videoOnly
                && !showVideo
                && userSetPoster.url === ""
            ) { 
                toast.error('Error: Missing poster');
                return;  
            }
        }

        if (creative.type === VIDEO_INTERSTITIAL) {
            const vids = creative.assets.every(asset => asset.url !== "");
            if (!vids) {
                toast.error('Error: Missing interstitial video');
                return;
            }
        }

        if (creative.type === GALLERY) {
            if (creative.assets.find(asset => asset.url === '') !== undefined) {
                // toast.error('Error: Missing image customs. \t Required: background, banner, logo and at least 3 images');
                toast.error('Error: Missing image customs.');
                return;
            }
        }

        if (creative.type === MOBILE_BANNER_VIDEO) {
            const vid = creative.assets.find(asset => asset.type === VIDEO);
            if (!vid?.url || vid?.url === '') {
                toast.error('Error: Missing video');
                return;
            }

            if (creative.assets.filter(img => img.type !== 'image').find(asset => asset.url === '') !== undefined) {
                toast.error('Error: Missing image customs. \t Required: background, top-image, bottom-image and button');
                return;
            }
        }

        if (creative.type === SCRATCH) {
            if (creative.assets.find(asset => asset.url === '' && asset.type !== 'Movable_Image') !== undefined) {
                toast.error('Error: Missing image customs');
                return;
            }
            if ((typeof creative.scratchComplete === 'number' && isNaN(creative.scratchComplete)) || (typeof creative.scratchComplete === 'string' && creative.scratchComplete.includes('-'))) {
                toast.error('Error: Invalid scratch completion input');
                return;
            } else {
                if (creative.scratchComplete < 0 || creative.scratchComplete > 100) {
                    toast.error('Error: scratch completion must be a number between 0 - 100');
                    return;
                }
            }
        }

        if (creative.type === BOUNCE_GAME) {

        }

        if (creative.type === VIDEO_TIMER_BANNER || creative.type === DESKTOP_TIMER_BANNER) {
            const vid = creative.assets.find(asset => asset.type === VIDEO);
            if (!vid?.url || vid?.url === '') {
                toast.error('Error: Missing video');
                return;
            }

            if (creative.assets.filter(img => img.type !== 'image').find(asset => asset.url === '') !== undefined) {
                toast.error('Error: Missing image customs. \t Required: background, top-image, bottom-image and button');
                return;
            }

            if (creative.showTimer && new Date(creative.timerEndDate) < new Date()) {
                toast.error('Error: End Date cannot be earlier than the current time');
                return;
            }
        }

        if (creative.type === VIDEO_GALLERY) {
            const vid = creative.assets.find(asset => asset.type === VIDEO);
            if (!vid?.url || vid?.url === '') {
                toast.error('Error: Missing video');
                return;
            }

            if (creative.assets.find(asset => asset.url === '') !== undefined) {
                // toast.error('Error: Missing image customs. \t Required: background, banner, logo and at least 3 images');
                toast.error('Error: Missing image customs.');
                return;
            }
        }
        if(creative.type === STICKY_MOBILE){
            const validAssets = creative.assets.filter(as => as.url !== '');
            if(validAssets.length < 1) {
                toast.error('Error: need at least 1 image');
                return;
            }
        }
        setRequestingData(true);

        let result = [];
        for (let asset of creative.assets) {
            let mimeTypeRegex, mimeType;
            const isEmpty = asset.url === ""; // skip compression if no video or image was selected

            if(!isEmpty) {
                mimeTypeRegex = /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/;
                mimeType = asset.url.match(mimeTypeRegex)[1];
            }

            if (!isEmpty && currentVideos) { // if edit creative
                const videoDidNotChange = currentVideos.find(currentVideo => asset.url === currentVideo.url);
                if (!videoDidNotChange) { // if new video was uploaded
                    if (mimeType === 'video/mp4' || mimeType === 'image/gif') {
                        toast.warning("Your file is getting resized and compressed...");
                        const compressedFile = await resizeIfFileTooBig(asset, mimeType, false);
                        if (!compressedFile) {
                            setRequestingData(false);
                            toast.warning('Could not compress the file. Please try raise compression power or upload new file.', { autoClose: false });
                            return;
                        }
                        result.push({ ...asset, url: compressedFile });
                    } else {
                        result.push(asset);
                    }
                } else { // else video did not change
                    result.push(asset);
                    continue;
                }
            } else if(!isEmpty) { // else new creative
                const mimeTypeRegex = /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/;
                const mimeType = asset.url.match(mimeTypeRegex)[1];
                if (mimeType === 'video/mp4' || mimeType === 'image/gif') {
                    toast.warning("Your file is getting resized and compressed...");
                    const compressedFile = await resizeIfFileTooBig(asset, mimeType, false);
                    if (!compressedFile) {
                        setRequestingData(false);
                        toast.warning('Could not compress the file. Please try raise compression power or upload new file.', { autoClose: false });
                        return;
                    }
                    result.push({ ...asset, url: compressedFile });
                } else {
                    const resizedAsset = await resizeIfImageTooBig(asset, mimeType);
                    result.push({...asset, url: resizedAsset});
                }
            }
        }
   
        const tempCreative = { ...creative, assets: result }
        const creativeForSave = formatCreativeForSave(tempCreative, images, texts, shapes);
        setTimeout(() => {
            postCreative(authToken, creativeForSave, handleAddCreativeCallback);
        }, 500);
    };

    const resizeIfFileTooBig = async (asset, mimeType, abortCreativeSubmition) => {
  
        const controller = new AbortController(); // create new AbortController
        const signal = controller.signal; // get the signal from the controller

        const res = await fetch(asset.url)
        const blob = await res.blob();
        const item = new File(
            [blob], 
            mimeType.replace('/', '.'), 
            { type: mimeType }
        );

        // this function handles mp4 and gif 
        // so we check if its a gif first and handle it
        const sizeObj = {
            width: asset.width, 
            height: asset.height,
        }

        // add more data for gif compression 
        if(mimeType === 'image/gif') {
            sizeObj.compression = creative.compression;
        }
    
        // add more data for mp4 compression 
        if(mimeType === 'video/mp4') {
            sizeObj.duration = asset.duration;
            sizeObj.size = asset.size;
        }
    
        try {
            let result;
            if(mimeType === 'image/gif')
                result = await resizeGif(item, sizeObj, signal);
            
            if(mimeType === 'video/mp4')
                result = await resizeVideo(item, sizeObj);


            if (abortCreativeSubmition) {
                controller.abort();
                return null;
            }

            if (result.fileSize < 4 && mimeType === 'image/gif') {
                return result.base64File;
            } else if (mimeType === 'image/gif') {
                const roundedResult = Math.round((result.fileSize + Number.EPSILON) * 100) / 100;
                toast.error(`Error: File size exceeded 4MB. Got ${roundedResult}MB`);
                return await resizeIfFileTooBig({ url: result.base64File, width: asset.width, height: asset.height }, mimeType, true);
            }

            // return the result 
            return result.base64File;  
        } catch (e) {
            toast.error(e.message);
            return false;
        }
    };

    const resizeIfImageTooBig = (asset, mimeType) => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const img = new Image();
            img.src = asset.url;

            img.onload = function () {
                // Set canvas dimensions to the image dimensions
                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, img.width, img.height);

                // Adjust quality to reduce file size
                const quality = imageCompression; // Change this value between 0 and 1 to adjust quality

                // Get the compressed image as base64
                const compressedDataUrl = canvas.toDataURL(mimeType, mimeType === 'image/jpeg' ? quality : 1);

                // Optional: Convert base64 to Blob and calculate size
                // const compressedBlob = dataURLToBlob(compressedDataUrl);

                // Clean up by removing the canvas from the DOM
                canvas.remove();

                // Resolve the promise with the compressed data URL
                resolve(compressedDataUrl);
            };

            img.onerror = function (error) {
                reject(error);
            };

        });

        // function dataURLToBlob(dataURL) {
        //     const byteString = atob(dataURL.split(',')[1]);
        //     const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        //     const ab = new ArrayBuffer(byteString.length);
        //     const ia = new Uint8Array(ab);
        //     for (let i = 0; i < byteString.length; i++) {
        //         ia[i] = byteString.charCodeAt(i);
        //     }
        //     return new Blob([ab], { type: mimeString });
        // }
    }

    const handleAddCreativeCallback = (data) => {
        setRequestingData(false);
        if (data.error === 0) {
            navigate(MAIN);
            return;
        }
    };

    useEffect(() => {
        clearAllBannerStyleTags();
        if (currentPath !== CREATIVE) {
            if (!didMountRef.current) {
                didMountRef.current = true;
                getCreative(authToken, currentPath, handleCreativeInit);
            }
        }
        else {
            if (!didMountRef.current) {
                didMountRef.current = true;
                setRequestingData(false);
            }
        }
    }, [authToken, currentPath, creative.type, creative.campaignId, handleCreativeInit]);

    useEffect(() => {
        if (!requestingData) {
            let combined = [...texts, ...images];
            for (let i = 0; i < combined.length; i++) {
                let item = combined[i];
                let className = getAnimationClassName(item.uiId, item.animationType, item.iterationType, item.animationDelay, item.animationDuration);
                if (document.querySelectorAll(`style[data-animation-id="${className}"]`).length === 0) {
                    getAnimationData(item, item.animationType, item.iterationType, item.animationDelay, item.animationDuration);
                }
            }
        }
    }, [requestingData, texts, images]);

    const renderCreativeByType = () => {
        switch (creative.type) {
            case CUSTOM_BANNER:
                return <Banner background={background} />;
            case INTERSTITIAL:
                return <Interstitial background={background} />;
            case HALF_HALF:
                return <HalfAndHalf background={background} />;
            case INLINE:
                return <Inline background={background} />;
            case VIDEO_INTERSTITIAL:
                return <VideoInterstitial background={background} />;
            case GALLERY:
                return <Gallery background={background} />;
            case MOBILE_BANNER_VIDEO:
                return <MobileBannerVideo background={background} />;
            case FLIPPER:
                return <Flipper background={background} />;
            case SCRATCH:
                return <Scratch background={background} />;
            case BOUNCE_GAME:
                return <BounceGame background={background} />;
            case VIDEO_TIMER_BANNER:
                return <VideoTimerBanner background={background} />;
            case DESKTOP_TIMER_BANNER:
                return <DesktopTimerBanner background={background} />;
            case VIDEO_GALLERY:
                return <VideoGallery background={background} />;
            case COLLECT_OR_EVADE_GAME:
                return <CollectOrEvadeGame background={background} />;
            case PROGRAM_VIDEO:
                return <ProgramVideo background={background} />;
            case HALF_VIDEO:
                return <HalfVideo background={background} />;
            case UNIVERSAL:
                return <Universal background={background} />;
            default:
                return null;
        }
    };

    const handleTabChange = (event) => {
        const newTabIndex = creativeTabs.indexOf(event.target.value);
        setSelectedTabIndex(newTabIndex);
    };

    return (
        <div className="page-container creative-page">
            {requestingData && <LoadingIndicator />}
            <div className='creative-page-container'>
                <ToggleButtonGroup
                    color="primary"
                    // value={alignment}
                    exclusive
                    // onChange={handleChange}
                    aria-label="Platform"
                    sx={{
                        borderRadius: '0',
                    }}
                >
                    {creativeTabs.map((tab, index) => (
                        <ToggleButton
                            key={`${tab}-${index}`}
                            value={tab}
                            onClick={handleTabChange}
                            sx={{
                                width: '100%',
                                textTransform: 'none',
                                border: 'none',
                                color: selectedTabIndex !== index ? 'white' : 'black',
                                backgroundColor: selectedTabIndex !== index ? '#9BD4F5' : 'white',
                                fontSize: '1vw',
                                borderRadius: '0',
                                '&:hover': {
                                    backgroundColor: selectedTabIndex !== index ? '#83d1ff' : 'white',
                                }
                            }}
                            disabled={(index === 1 || index === 2) && !creative.type ? true : false}
                        >
                            {tab}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                {selectedTabIndex === 0 && <NewCreative creative={creative} creativeType={currentType} />}
                {selectedTabIndex === 1 && <CreativeAssetsMenu itemsCount={images.length + texts.length + shapes.length} images={images} texts={texts} shapes={shapes} />}
                {selectedTabIndex === 2 && <CreativeRedirectionMenu />}
                <ActionFooter proceedCallback={handleProceed} />
            </div>
            <div className='creative-preview-container'>
                {renderCreativeByType()}
            </div>
        </div>
    )
}