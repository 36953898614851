export const tokenKey = 'dsUser';
let domain = 'https://www.adsil1.com/Office'; // development
// let domain = 'http://193.34.144.96:9096'; // development

if (process.env.NODE_ENV !== 'development') { // production
    domain = 'https://www.adsil1.com/Office';
}
const apiURL = domain + '/api/v1';

export async function validateAuthToken(token, callback) {
    if (token) {
        const reqUrl = apiURL + '/security/authentication';
        const requestOptions = {
            headers: { authorization: "Bearer " + token }
        };
        serverRequest(reqUrl, requestOptions, callback, token);
    }
}

export async function getAuthToken(email, password, callback) {
    const reqUrl = apiURL + '/security/authentication';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password })
    };
    serverRequest(reqUrl, requestOptions, callback);
}

export async function getUsers(token, callback) {
    const reqUrl = apiURL + '/user/list';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function postUser(token, user, callback) {
    const reqUrl = apiURL + '/user';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ user: user })
    };
    serverRequest(reqUrl, requestOptions, callback, token, user);
}

export async function putUser(token, user, callback) {
    const reqUrl = apiURL + '/user';
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ user: user })
    };
    serverRequest(reqUrl, requestOptions, callback, token, user);
}


export async function getClients(token, callback) {
    const reqUrl = apiURL + '/client/list';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function postClient(token, client, callback) {
    const reqUrl = apiURL + '/client';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ client: client })
    };
    serverRequest(reqUrl, requestOptions, callback, token, client);
}

export async function getCampaigns(token, callback) {
    const reqUrl = apiURL + '/campaign/list';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function postCampaign(token, campaign, callback) {
    const reqUrl = apiURL + '/campaign';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ campaign: campaign })
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function postCreative(token, creative, callback) {
    // console.log(creative);
    const reqUrl = apiURL + '/creative';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify({ creative: creative })
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function getCreative(token, id, callback) {
    const reqUrl = apiURL + '/creative/' + id;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function deleteCreative(token, id, callback) {
    const reqUrl = apiURL + '/creative/' + id;
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function getCreativeList(token, callback) {
    const reqUrl = apiURL + '/creative/list';
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token }
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function exportReports(token, requestData, callback) {
    const reqUrl = apiURL + '/creative/export';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + token },
        body: JSON.stringify(requestData)
    };
    serverRequest(reqUrl, requestOptions, callback, token);
}

export async function resizeVideo(requestData, size) {
    
    // if 16by9 will resize to 512x288
    let [ is16by9, isHighRes ] =  [false, false];
    
    // these bitrates will be used in the  
    // compression step 
    const recommendedBitrate = {
        "512x288": 300,
        "320x410": 300,
        "640x820": 600,
        "640x960": 600,
        "640x1080": 700,
        "640x1200": 700,
        "640x1280": 700,
        "640x1370": 800
    }

    // 16 by 9 check then check if high res 
    const targetAspectRation = 16 / 9;
    const videoAspectRation = size.width / size.height;
    const tolerance = 0.00005;
        
    if(Math.abs(videoAspectRation - targetAspectRation) < tolerance)
        is16by9 = true;

    if(is16by9 && size.width>512 && size.height>288)
        isHighRes = true;

    try {
        const convertForm = await convertGetUploadForm();
        let taskID = convertForm.id;
        await convertUploadFile(convertForm, requestData);

        // if video is 16/9 and higher res then needed 
        // we resize first then continue to compress
        if(is16by9 && isHighRes) {
            const resizeTask = await convertResizeVideo(taskID);
            const resizeTaskId = resizeTask.id;

            for(let i=0; i<5; i++) {
                await new Promise((resolve) => setTimeout(resolve, 2000));

                const task = await convertGetTaskById(resizeTaskId);

                if (task.status === "failed")
                    throw new Error("task failed");
    
                if (["processing", "created"].includes(task.status))
                    continue;

                if(task.status === "completed"){
                    taskID = task.id;
                    break;
                }
            }
        }

        // initiate a compress job using the default values set by us
        // and capture the jobId for result extraction.
        // check the var to see the defaults.
        const bitrate = recommendedBitrate[`${size.width}x${size.height}`];
        const compressData = await convertCompressVideo(taskID, bitrate);
        const compressJobId = compressData.id;

        // loop while waiting for file to complete compressing
        // loop will ran 5 time for 10 seconds. adjust as needed.
        for(let i=0; i<5; i++) {
 
            await new Promise((resolve) => setTimeout(resolve, 2000));
            
            const task = await convertGetTaskById(compressJobId);

            if (task.status === "failed")
                throw new Error("task failed");

            if (["processing", "created"].includes(task.status))
                continue;

            // task is done, extract the mp4 from the url and return the base64
            const videoFetch = await fetch(task.result.url);
            
            if (!videoFetch.ok)
                throw new Error(`failed fetching the mp4 from freeConvert, status: ${videoFetch}.status}`);

            const videoBlob = await videoFetch.blob();

            const result = new Promise((res, rej) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    res({
                        fileSize: task.result.size / 1024 / 1024, // this is MB size
                        base64File: reader.result
                    });
                }
                reader.onerror = () => {
                    rej(new Error(`failed to convert video blob to base64`));
                }
                reader.readAsDataURL(videoBlob);
            }) 
            return result;   
        }
    } catch (e) {
        console.log(e.message);
    }
}

export async function resizeGif(requestData, size, signal) {

    var formdata = new FormData();
    formdata.append('file', requestData);
    const reqUrl = `https://coral-app-htq38.ondigitalocean.app/api/file/compress?width=${size.width}&height=${size.height}&compression=${size.compression || '28'}`;
    const requestOptions = {
        method: 'POST',
        headers: { 'challenge': 'fJM9HD06W4tWTKSTJCxScxGNQYmBsET9', 'Access-Control-Allow-Headers': '*' },
        body: formdata,
        signal: signal
    };

    try {
        const response = await fetch(reqUrl, requestOptions);
        // console.log(response.headers.get('content-length'));
        const blob = await response.blob();
        const fileSize = blob.size / 1024 / 1024;
        const result = await convertToBase64(blob)
        return { base64File: result, fileSize };
    } catch (error) {
        console.log(error)
    };

}

function convertToBase64(blob) {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result);
            };
        } catch (error) {
            reject(error);
        }
    });
}

async function serverRequest(url, options, callback, token = false, client) {
    await fetch(url, options)
        .then((response) => {
            if (response.ok) return response.json();
            throw new Error('Something went wrong');
        })
        .then((responseJson) => {
            if (token) responseJson.token = token;
            if (client) {
                callback(responseJson, client);
            } else {
                callback(responseJson);
            }
        })
        .catch((error, e, a, b) => {
            console.log(error)
            callback({ error: 4, message: 'Something went wrong' });
        });
}


// FreeConvert API
const CONVERTBASEURL = 'https://api.freeconvert.com/v1'
const CONVERTAPIKEY = 'api_production_44bcc86e16eda44c3fb21440d6fa196cadb9e3980ae113905e54569d98a42838.6716399a6a9bfd290859cbd8.6728efb810fdaf59dc4dbe43'


const convertGetUploadForm = async() => {
  
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${CONVERTAPIKEY}`,
    };

    const res = await fetch(`${CONVERTBASEURL}/process/import/upload`, {
        method: "POST",
        headers: headers,
    });

    // always check if response in 200-209
    if(!res.ok) 
        throw new Error(`getUploadFormUrl not 200-209, status code: ${res.status}`);
    
    // convert to json
    const resJson = await res.json();

    // return object all the data for the form
    return {
        "url": resJson.result.form.url,
        "parameters": resJson.result.form.parameters,
        "id": resJson.id
    }

}


const convertUploadFile = async(convertData, fileData) => {

    let formData = new FormData();
    formData.append('file', fileData);
    for (const parameter in convertData.parameters) {
        formData.append(parameter, convertData.parameters[parameter]);
    }

    const res = await fetch(convertData.url, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${CONVERTAPIKEY}`,
        },
        body: formData
    });

    if(!res.ok) 
        throw new Error(`uploadFileToConvert status code: ${res.status}`);
    
    const resJson = await res.json();

    return resJson;

}

const convertResizeVideo = async (id) => {
    const res = await fetch(`${CONVERTBASEURL}/process/convert`,{
        method: "POST",
        headers: {
            "Authorization": `Bearer ${CONVERTAPIKEY}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify({
            "input": id,
            "input_format": "mp4",
            "output_format": "mp4",
            "options": {
                "adjust_video_settings": "change-resolution",
                "video_codec": "h264",
                "video_screen_size": "custom",
                "video_custom_width": "512",
                "video_custom_height": "288",
                "subtitle_add": "none"
            }
        })
    });

    if(!res.ok) 
        throw new Error(`convertCompressVideo not 200-209, status code: ${res.status}`);

    const resJson = await res.json();

    return resJson;
}

const convertCompressVideo = async (id, bitrate) => {
    const res = await fetch(`${CONVERTBASEURL}/process/compress`,{
        method: "POST",
        headers: {
            "Authorization": `Bearer ${CONVERTAPIKEY}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify({
            "input": id,
            "input_format": "mp4",
            "output_format": "mp4",
            "options": {
                "compress_video": "by_max_bitrate",
                "video_codec_compress": "h264",
                "video_compress_max_bitrate": bitrate,
                "video_compressor_bufsize": bitrate*2,
                "video_compress_crf_x264": 21
            }
        })
    });

    if(!res.ok) 
        throw new Error(`convertCompressVideo not 200-209, status code: ${res.status}`);

    const resJson = await res.json();

    return resJson;
}


const convertGetTaskById = async (id) => {
    
    const res = await fetch(`${CONVERTBASEURL}/process/tasks/${id}`,{
        headers: {
            "Authorization": `Bearer ${CONVERTAPIKEY}`,
        }
    });

    if (!res.ok) 
        throw new Error(`convertGetTaskById not 200-209, status code: ${res.status}`);

    const resJson = await res.json();

    return resJson;
}








